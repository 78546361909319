import React, { useState } from 'react';

// Import danh sách ảnh người mẫu
import modelImage1 from './assets/model/00017_00.jpg';
import modelImage2 from './assets/model/00641_00.jpg';
import modelImage3 from './assets/model/01201_00.jpg';
import modelImage4 from './assets/model/01376_00.jpg';
import modelImage5 from './assets/model/01564_00.jpg';
import modelImage6 from './assets/model/01705_00.jpg';
import modelImage7 from './assets/model/02254_00.jpg';
import modelImage8 from './assets/model/02790_00.jpg';
import modelImage9 from './assets/model/03192_00.jpg';
import modelImage10 from './assets/model/03641_00.jpg';
import modelImage11 from './assets/model/04096_00.jpg';
import modelImage12 from './assets/model/04351_00.jpg';
import modelImage13 from './assets/model/05378_00.jpg';
import modelImage14 from './assets/model/05650_00.jpg';
import modelImage15 from './assets/model/05876_00.jpg';
import modelImage16 from './assets/model/06646_00.jpg';
import modelImage17 from './assets/model/06753_00.jpg';
import modelImage18 from './assets/model/07180_00.jpg';
import modelImage19 from './assets/model/07410_00.jpg';
import modelImage20 from './assets/model/07703_00.jpg';
import modelImage21 from './assets/model/07770_00.jpg';
import modelImage22 from './assets/model/08297_00.jpg';
import modelImage23 from './assets/model/09720_00.jpg';
import modelImage24 from './assets/model/09882_00.jpg';
import modelImage25 from './assets/model/09946_00.jpg';
import modelImage26 from './assets/model/11872_00.jpg';
import modelImage27 from './assets/model/12399_00.jpg';
import modelImage28 from './assets/model/12538_00.jpg';
import modelImage29 from './assets/model/13015_00.jpg';
import modelImage30 from './assets/model/13468_00.jpg';

// Import danh sách ảnh áo mẫu
import shirtImage1 from './assets/images/00000_00.jpg';
import shirtImage2 from './assets/images/00001_00.jpg';
import shirtImage3 from './assets/images/00002_00.jpg';
import shirtImage4 from './assets/images/00003_00.jpg';
import shirtImage6 from './assets/images/00005_00.jpg';
import shirtImage8 from './assets/images/00007_00.jpg';
import shirtImage10 from './assets/images/00009_00.jpg';
import shirtImage11 from './assets/images/00010_00.jpg';
import shirtImage12 from './assets/images/00011_00.jpg';
import shirtImage13 from './assets/images/00012_00.jpg';
import shirtImage15 from './assets/images/00014_00.jpg';
import shirtImage16 from './assets/images/00015_00.jpg';
import shirtImage17 from './assets/images/00016_00.jpg';
import shirtImage19 from './assets/images/00018_00.jpg';
import shirtImage20 from './assets/images/00019_00.jpg';
import shirtImage23 from './assets/images/00022_00.jpg';
import shirtImage24 from './assets/images/00023_00.jpg';
import shirtImage25 from './assets/images/00024_00.jpg';
import shirtImage26 from './assets/images/00025_00.jpg';
import shirtImage27 from './assets/images/00026_00.jpg';
import shirtImage29 from './assets/images/00028_00.jpg';
import shirtImage30 from './assets/images/00029_00.jpg';
import shirtImage31 from './assets/images/00030_00.jpg';
import shirtImage32 from './assets/images/00031_00.jpg';
import shirtImage33 from './assets/images/00032_00.jpg';
import shirtImage34 from './assets/images/00033_00.jpg';
import shirtImage37 from './assets/images/00036_00.jpg';
import shirtImage39 from './assets/images/00038_00.jpg';
import shirtImage41 from './assets/images/00040_00.jpg';
import shirtImage42 from './assets/images/00041_00.jpg';
import shirtImage43 from './assets/images/00042_00.jpg';
import shirtImage44 from './assets/images/00043_00.jpg';
import shirtImage45 from './assets/images/00044_00.jpg';
import shirtImage46 from './assets/images/00045_00.jpg';
import shirtImage47 from './assets/images/00046_00.jpg';
import shirtImage48 from './assets/images/00047_00.jpg';
import shirtImage49 from './assets/images/00048_00.jpg';
import shirtImage50 from './assets/images/00049_00.jpg';
import shirtImage51 from './assets/images/00050_00.jpg';

function App() {
  const [modelImage, setModelImage] = useState(null);
  const [shirtImage, setShirtImage] = useState(null);
  const [resultImage, setResultImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [clickCount, setClickCount] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [apiUrl, setApiUrl] = useState(localStorage.getItem('apiUrl') || 'https://b9f1-171-224-17-203.ngrok-free.app/upload_image');
  const [tempApiUrl, setTempApiUrl] = useState(apiUrl);

  // Danh sách ảnh người mẫu
  const modelImages = [
    { src: modelImage1, filename: 'modelImage1.jpg' },
    { src: modelImage2, filename: 'modelImage2.jpg' },
    { src: modelImage3, filename: 'modelImage3.jpg' },
    { src: modelImage4, filename: 'modelImage4.jpg' },
    { src: modelImage5, filename: 'modelImage5.jpg' },
    { src: modelImage6, filename: 'modelImage6.jpg' },
    { src: modelImage7, filename: 'modelImage7.jpg' },
    { src: modelImage8, filename: 'modelImage8.jpg' },
    { src: modelImage9, filename: 'modelImage9.jpg' },
    { src: modelImage10, filename: 'modelImage10.jpg' },
    { src: modelImage11, filename: 'modelImage11.jpg' },
    { src: modelImage12, filename: 'modelImage12.jpg' },
    { src: modelImage13, filename: 'modelImage13.jpg' },
    { src: modelImage14, filename: 'modelImage14.jpg' },
    { src: modelImage15, filename: 'modelImage15.jpg' },
    { src: modelImage16, filename: 'modelImage16.jpg' },
    { src: modelImage17, filename: 'modelImage17.jpg' },
    { src: modelImage18, filename: 'modelImage18.jpg' },
    { src: modelImage19, filename: 'modelImage19.jpg' },
    { src: modelImage20, filename: 'modelImage20.jpg' },
    { src: modelImage21, filename: 'modelImage21.jpg' },
    { src: modelImage22, filename: 'modelImage22.jpg' },
    { src: modelImage23, filename: 'modelImage23.jpg' },
    { src: modelImage24, filename: 'modelImage24.jpg' },
    { src: modelImage25, filename: 'modelImage25.jpg' },
    { src: modelImage26, filename: 'modelImage26.jpg' },
    { src: modelImage27, filename: 'modelImage27.jpg' },
    { src: modelImage28, filename: 'modelImage28.jpg' },
    { src: modelImage29, filename: 'modelImage29.jpg' },
    { src: modelImage30, filename: 'modelImage30.jpg' },
  ];

  // Danh sách ảnh áo mẫu
  const shirtImages = [
    { src: shirtImage1, filename: '00000_00.jpg' },
    { src: shirtImage2, filename: '00001_00.jpg' },
    { src: shirtImage3, filename: '00002_00.jpg' },
    { src: shirtImage4, filename: '00003_00.jpg' },
    { src: shirtImage6, filename: '00005_00.jpg' },
    { src: shirtImage8, filename: '00007_00.jpg' },
    { src: shirtImage10, filename: '00009_00.jpg' },
    { src: shirtImage11, filename: '00010_00.jpg' },
    { src: shirtImage12, filename: '00011_00.jpg' },
    { src: shirtImage13, filename: '00012_00.jpg' },
    { src: shirtImage15, filename: '00014_00.jpg' },
    { src: shirtImage16, filename: '00015_00.jpg' },
    { src: shirtImage17, filename: '00016_00.jpg' },
    { src: shirtImage19, filename: '00018_00.jpg' },
    { src: shirtImage20, filename: '00019_00.jpg' },
    { src: shirtImage23, filename: '00022_00.jpg' },
    { src: shirtImage24, filename: '00023_00.jpg' },
    { src: shirtImage25, filename: '00024_00.jpg' },
    { src: shirtImage26, filename: '00025_00.jpg' },
    { src: shirtImage27, filename: '00026_00.jpg' },
    { src: shirtImage29, filename: '00028_00.jpg' },
    { src: shirtImage30, filename: '00029_00.jpg' },
    { src: shirtImage31, filename: '00030_00.jpg' },
    { src: shirtImage32, filename: '00031_00.jpg' },
    { src: shirtImage33, filename: '00032_00.jpg' },
    { src: shirtImage34, filename: '00033_00.jpg' },
    { src: shirtImage37, filename: '00036_00.jpg' },
    { src: shirtImage39, filename: '00038_00.jpg' },
    { src: shirtImage41, filename: '00040_00.jpg' },
    { src: shirtImage42, filename: '00041_00.jpg' },
    { src: shirtImage43, filename: '00042_00.jpg' },
    { src: shirtImage44, filename: '00043_00.jpg' },
    { src: shirtImage45, filename: '00044_00.jpg' },
    { src: shirtImage46, filename: '00045_00.jpg' },
    { src: shirtImage47, filename: '00046_00.jpg' },
    { src: shirtImage48, filename: '00047_00.jpg' },
    { src: shirtImage49, filename: '00048_00.jpg' },
    { src: shirtImage50, filename: '00049_00.jpg' },
    { src: shirtImage51, filename: '00050_00.jpg' },
  ];

  const handleModelUpload = (event) => {
    const file = event.target.files[0];
    console.log(file)
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setModelImage({ src: imageUrl, filename: file.name });
    }
  };

  const handleShirtUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setShirtImage({ file, url: imageUrl });
    }
  };

  // Đếm số lần nhấp vào tiêu đề
  const handleTitleClick = () => {
    setClickCount(prevCount => prevCount + 1);
    if (clickCount + 1 === 5) {
      setShowModal(true);
      setClickCount(0); // Reset lại bộ đếm sau khi hiển thị modal
    }
  };

  const handleSaveUrl = () => {
    setApiUrl(tempApiUrl);
    localStorage.setItem('apiUrl', tempApiUrl);
    setShowModal(false); // Đóng modal sau khi lưu URL
  };

  const handleCloseModal = () => {
    setShowModal(false); // Đóng modal mà không lưu thay đổi
  };
  // Chuyển đổi ảnh assets thành file
  const createFileFromImage = (src, filename) => {
    return fetch(src)
      .then((res) => res.blob())
      .then((blob) => {
        return new File([blob], filename, { type: blob.type });
      });
  };

  const handleTryOn = async () => {
    if (modelImage && shirtImage) {
      setIsLoading(true);
      
      // Sử dụng FormData để gửi dữ liệu ảnh
      const formdata = new FormData();

      // Nếu người dùng upload ảnh từ máy tính, gửi file
      if (modelImage.file) {
        formdata.append('in_file', modelImage.file, modelImage.file.name);
      } else {
        // Nếu chọn ảnh từ assets, chuyển đổi ảnh thành file
        const file = await createFileFromImage(modelImage.src, modelImage.filename);
        formdata.append('in_file', file, file.name);
      }

      // Tương tự với ảnh áo
      formdata.append('cloth', shirtImage.filename);

      const requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow',
      };

      // Gọi API
      fetch(apiUrl, requestOptions)
        .then((response) => response.blob()) // Nhận ảnh dưới dạng blob
        .then((blob) => {
          const imageUrl = URL.createObjectURL(blob); // Chuyển đổi blob thành URL
          setResultImage(imageUrl); // Hiển thị ảnh trả về
          setIsLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
        });
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center p-4">
      <h1 className="text-4xl font-bold mb-8 cursor-pointer" onClick={handleTitleClick}>
        AI Virtual Try-On Demo
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 w-full max-w-5xl">
        {/* Cột 1: Ảnh người mẫu */}
        <div className="bg-white p-4 rounded-lg shadow-lg">
          <h2 className="text-lg font-semibold mb-4">Ảnh người mẫu</h2>
          <input type="file" accept="image/*" onChange={handleModelUpload} className="mb-4" />
          <div className="flex space-x-4 overflow-x-scroll mb-4">
            {modelImages.map((img, index) => (
              <img
                key={index}
                src={img.src}
                alt={`Model ${index + 1}`}
                className="w-24 h-24 object-cover cursor-pointer"
                onClick={() => setModelImage({ src: img.src, filename: img.filename })}
              />
            ))}
          </div>
          <div className="w-full h-64 bg-gray-200 flex items-center justify-center rounded-lg">
            {modelImage ? (
              <img src={modelImage.src} alt="Model" className="max-h-full" />
            ) : (
              <span>Chưa có ảnh người mẫu</span>
            )}
          </div>
        </div>

        {/* Cột 2: Ảnh áo */}
        <div className="bg-white p-4 rounded-lg shadow-lg">
          <h2 className="text-lg font-semibold mb-4">Ảnh áo</h2>
          {/* <input type="file" accept="image/*" onChange={handleShirtUpload} className="mb-4" /> */}
          <br></br>
          <br></br>
          <div className="flex space-x-4 overflow-x-scroll mb-4">
            {shirtImages.map((img, index) => (
              <img
                key={index}
                src={img.src}
                alt={`Shirt ${index + 1}`}
                className="w-24 h-24 object-cover cursor-pointer"
                onClick={() => setShirtImage({ src: img.src, filename: img.filename })}
              />
            ))}
          </div>
          <div className="w-full h-64 bg-gray-200 flex items-center justify-center rounded-lg">
            {shirtImage ? (
              <img src={shirtImage.src} alt="Shirt" className="max-h-full" />
            ) : (
              <span>Chưa có ảnh áo</span>
            )}
          </div>
        </div>

        {/* Cột 3: Kết quả */}
        <div className="bg-white p-4 rounded-lg shadow-lg flex flex-col">
          <button
            onClick={handleTryOn}
            className="mb-4 w-full px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-700"
            disabled={!modelImage || !shirtImage || isLoading}
          >
            {isLoading ? 'Đang xử lý...' : 'Try-on'}
          </button>
          <div className="w-full h-64 bg-gray-200 flex items-center justify-center rounded-lg">
            {isLoading ? (
              <div className="loader"></div>
            ) : resultImage ? (
              <img src={resultImage} alt="Result" className="max-h-full" />
            ) : (
              <span>Chưa có kết quả</span>
            )}
          </div>
        </div>
        {/* Modal chỉnh sửa URL API */}
        {showModal && (
          <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center">
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h2 className="text-xl mb-4">Chỉnh sửa URL API</h2>
              <input
                type="text"
                value={tempApiUrl}
                onChange={(e) => setTempApiUrl(e.target.value)}
                className="w-full px-4 py-2 border rounded-lg mb-4"
              />
              <div className="flex justify-end">
                <button onClick={handleCloseModal} className="px-4 py-2 bg-gray-300 rounded-lg mr-2">
                  Hủy
                </button>
                <button onClick={handleSaveUrl} className="px-4 py-2 bg-blue-500 text-white rounded-lg">
                  Lưu
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
